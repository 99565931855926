/* eslint-disable array-callback-return */

import { ActionTypes } from './action';
import { IPurchaseOrderTypeRes } from "./types";


const initialState: IPurchaseOrderTypeRes = {
  optionsList: [],
  items: [],
  totalCount: 0,
};

const PurchaseOrderReducer = (state: IPurchaseOrderTypeRes = initialState, action): IPurchaseOrderTypeRes => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_PURCHASE_ORDER_MODULE_LIST:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
          ...action?.payload,
          optionsList: optionsList,
        
      };
    }
    case ActionTypes.SET_UPDATE_STATUS:{
      const updatedList = state?.items.map(
        (item) => item.id === action.payload?.id?{...item,status: action?.payload?.status}:item
      );
      return {
        ...state,
       items:updatedList
      };
    }
    case ActionTypes.SET_DELETE_ORDER:{
      const updatedList = state.items.filter(item => item.id !== action.payload.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
    case ActionTypes.SET_PRODUCT_DETAILS:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
          ...action?.payload,
          optionsList: optionsList,
        
      };
    }
    default:
      return state;
  }
};

export default PurchaseOrderReducer;
