export const customerServicePermission = [
    { name: "create", label: "Create", api_list: "searchService:createCombinations,customerService:create,customerService:list,customerService:get,searchService:list", value: false },
    { name: "update", label: "Update", api_list: "searchService:createCombinations,customerService:update,customerService:updateStatus,customerService:list,customerService:get,searchService:list", value: false },
    { name: "delete", label: "Delete", api_list: "searchService:createCombinations,customerService:delete,customerService:list,searchService:list", value: false },
    { name: "list", label: "View Only", api_list: "searchService:createCombinations,customerService:list,customerService:get,searchService:list", value: false },
];
export const supplierServicePermission = [
    { name: "create", label: "Create", api_list: "searchService:createCombinations,supplierService:create,supplierService:list,supplierService:get,searchService:list", value: false },
    { name: "update", label: "Update", api_list: "searchService:createCombinations,supplierService:update,supplierService:updateStatus,supplierService:list,supplierService:get,searchService:list", value: false },
    { name: "delete", label: "Delete", api_list: "searchService:createCombinations,supplierService:delete,supplierService:list,searchService:list", value: false },
    { name: "list", label: "View Only", api_list: "searchService:createCombinations,supplierService:list,supplierService:get,searchService:list", value: false },
]
export const projectServicePermission = [
    { name: "create", label: "Create", value: false, api_list: "intuitService:terms,searchService:createCombinations,projectService:archive,projectService:recover,projectService:assign,packageService:create,customerService:list,drawingService:create,drawingService:list,customerService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:create,projectService:list,projectService:get,intuitService:items,searchService:list" },
    { name: "update", label: "Update", value: false, api_list: "ntuitService:terms,searchService:createCombinations,projectService:archive,projectService:recover,projectService:updateEscalation,packageService:update,packageService:updateStatus,customerService:list,drawingService:update,drawingService:list,customerService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:update,projectService:updateStatus,projectService:updatePricing,projectService:list,projectService:get,intuitService:items,searchService:list" },
    { name: "delete", label: "Delete", value: false, api_list: "searchService:createCombinations,packageService:delete,projectService:delete,drawingService:create,drawingService:list,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:list,searchService:list" },
    { name: "list", label: "View Only", value: false, api_list: "customerService:list,searchService:createCombinations,shopService:list,shopService:get,packageService:list,projectService:list,projectService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,searchService:list" },
]
export const inquiryServicePermission = [
    { name: "create", label: "Create", value: false, api_list: "saleService:getTax,saleService:getInvoice,searchService:createCombinations,supplierService:list,supplierService:get,saleService:editInvoice,productService:list,inquirySaleService:rollback,emailTemplateService:get,inquiryService:sale,inquirySaleService:pdf,saleService:sendBOL,inquirySaleService:getTaxRate,deliveryByService:list,taxService:list,productUnitService:list,inquirySaleService:create,inquiryService:supplier_list,customerService:list,customerService:get,gradeService:list,inquirySaleService:list,inquirySaleService:get,inquirySaleService:po,inquiryService:create,inquiryService:list,inquiryService:get,inquirySaleService:get,intuitService:terms" },
    { name: "update", label: "Update", value: false, api_list: "saleService:getTax,saleService:getInvoice,searchService:createCombinations,supplierService:list,supplierService:get,saleService:editInvoice,productService:list,inquirySaleService:rollback,emailTemplateService:get,inquiryService:recover,inquirySaleService:pdf,saleService:sendBOL,inquirySaleService:getTaxRate,inquirySaleService:update,deliveryByService:list,inquirySaleService:recover,inquiryService:sale,taxService:list,productUnitService:list,inquiryService:supplier_list,inquiryService:supplier,inquiryService:supplier_price,inquiryService:supplier_sale,customerService:get,customerService:list,customerService:get,gradeService:list,inquirySaleService:list,inquirySaleService:get,inquirySaleService:accept,inquirySaleService:po,inquirySaleService:schedule,inquirySaleService:deliver,inquirySaleService:schedulePadlet,inquiryService:update,inquiryService:updateStatus,inquiryService:list,inquiryService:get,inquirySaleService:get,intuitService:terms" },
    { name: "delete", label: "Delete", value: false, api_list: "searchService:createCombinations,supplierService:list,supplierService:get,inquiryService:archive,customerService:list,gradeService:list,inquirySaleService:list,inquiryService:delete,inquirySaleService:reject,inquirySaleService:archive,inquiryService:list" },
    { name: "list", label: "View Only", value: false, api_list: "customerService:list,searchService:createCombinations,supplierService:list,supplierService:get,inquiryService:list,inquiryService:supplier_list,inquiryService:get,inquirySaleService:list,inquirySaleService:get,searchService:list,supplierService:list" },
]

export const saleOrderPermission = [
    { name: "create", label: "Create", value: false, api_list: "searchService:createCombinations,supplierService:list,productUnitService:list,customerService:list,saleOrderService:create,saleOrderService:accept,saleOrderService:reject,saleOrderService:recover,saleOrderService:pdf" },
    { name: "update", label: "Update", value: false, api_list: "searchService:createCombinations,supplierService:list,productUnitService:list,customerService:list,saleOrderService:update,saleOrderService:get,saleOrderService:reject,saleOrderService:archive,saleOrderService:recover,saleOrderService:pdf" },
    { name: "delete", label: "Delete", value: false, api_list: "" },
    { name: "list", label: "View Only", value: false, api_list: "searchService:createCombinations,saleOrderService:list" },
]
export const purchaseOrderPermission = [
    { name: "create", label: "Create", value: false, api_list: "searchService:createCombinations,supplierService:list,productUnitService:list,customerService:list,purchaseOrderService:create,purchaseOrderService:accept,purchaseOrderService:reject,purchaseOrderService:recover,purchaseOrderService:pdf,purchaseOrderService:list" },
    { name: "update", label: "Update", value: false, api_list: "searchService:createCombinations,supplierService:list,productUnitService:list,customerService:list,purchaseOrderService:update,purchaseOrderService:get,purchaseOrderService:reject,purchaseOrderService:archive,purchaseOrderService:recover,purchaseOrderService:pdf,purchaseOrderService:list" },
    { name: "delete", label: "Delete", value: false, api_list: "" },
    { name: "list", label: "View Only", value: false, api_list: "searchService:createCombinations,purchaseOrderService:list" },
]
export const editInventoryPermission = [
    { name: "create", label: "Create", value: false,},
    { name: "update", label: "Update", value: false, api_list: "productService:list,locationFacilityService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:create,productLengthService:list,inventoryService:search,searchService:list,inventoryService:edit" },
    { name: "delete", label: "Delete", value: false,},
    { name: "list", label: "View Only", value: false,},
]
export const fabricationServicePermission = [
    { name: "create", label: "Create", value: false, api_list: "fabricationService:create,customerService:list,gradeService:list,locationFacilityService:list,productLengthService:list,inventoryService:get,supplierService:list,projectService:list,projectService:get,supplierTypeService:list,fabricationScheduleService:schedule,fabricationScheduleService:schedulePadlet,fabricationScheduleService:deliver,fabricationService:list,fabricationScheduleService:schedule,fabricationScheduleService:schedulePadlet,fabricationService:get,fabricationScheduleService:get,coatingService:list,sizeSpecificationService:list,intuitService:terms" },
    { name: "update", label: "Update", value: false, api_list: "fabricationService:update,inventoryService:get,customerService:list,locationFacilityService:list,gradeService:list,supplierService:list,projectService:list,supplierTypeService:list,projectService:get,fabricationService:updateStatus,fabricationService:list,fabricationScheduleService:schedule,fabricationScheduleService:schedulePadlet,fabricationScheduleService:deliver,fabricationService:get,fabricationScheduleService:get,intuitService:terms" },
    { name: "delete", label: "Delete", value: false, api_list: "fabricationService:delete,fabricationService:list" },
    { name: "list", label: "View Only", value: false, api_list: "fabricationService:list,fabricationService:get,customerService:list,gradeService:list,supplierService:list,projectService:list,supplierTypeService:list" },
]
export const stockServicePermission = [
    { name: "create", label: "Create", value: false, api_list: "stockService:create,intuitService:taxRate,locationFacilityService:list,intuitService:terms,stockService:list,stockService:get,stockScheduleService:schedule,projectService:get,stockService:get,stockScheduleService:get,stockService:get,stockScheduleService:deliver" },
    { name: "update", label: "Update", value: false, api_list: "stockService:update,intuitService:taxRate,locationFacilityService:list,intuitService:terms,stockService:updateStatus,stockService:list,stockService:get,projectService:get,stockService:get,stockScheduleService:get,stockService:get,stockScheduleService:deliver" },
    { name: "delete", label: "Delete", value: false, api_list: "stockService:delete,stockService:list" },
    { name: "list", label: "View Only", value: false, api_list: "stockService:list,stockService:get" },
]
export const logisticServicePermission = [
    { name: "create", label: "Create", value: false, },
    { name: "update", label: "Update", value: false, },
    { name: "delete", label: "Delete", value: false, },
    { name: "list", label: "View Only", value: false, api_list: "searchService:createCombinations,logisticService:list,logisticService:get,deliveryByService:list,logisticService:location" },
]
export const inventoryServicePermission = [
    { name: "create", label: "Create", value: false, api_list: "searchService:createCombinations,inventoryTransferService:createChild,inventoryTransferService:history,productService:list,locationFacilityService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:create,productLengthService:list,inventoryService:get" },
    { name: "update", label: "Update", value: false, api_list: "searchService:createCombinations,inventoryTransferService:editChild,inventoryTransferService:history,productService:list,locationFacilityService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:update,inventoryService:updateStatus,inventoryService:get" },
    { name: "delete", label: "Delete", value: false, api_list: "searchService:createCombinations,inventoryTransferService:history,productService:list,locationFacilityService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:delete" },
    { name: "list", label: "View Only", value: false, api_list: "gradeService:list,searchService:createCombinations,inventoryTransferService:history,productService:list,locationFacilityService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:list,inventoryService:stock,inventoryService:get" },
]
export const proposalServicePermission = [
    { name: "create", label: "Create", api_list: "searchService:createCombinations,projectScopeService:list,projectScopeService:get,proposalService:create,proposalService:list,proposalService:get,proposalService:sent,proposalService:accept,proposalService:upload,searchService:list", value: false },
    { name: "update", label: "Update", api_list: "searchService:createCombinations,projectScopeService:list,projectScopeService:get,proposalService:recover,proposalService:update,proposalService:updateStatus,proposalService:list,proposalService:get,proposalService:sent,searchService:list", value: false },
    { name: "delete", label: "Delete", api_list: "searchService:createCombinations,projectScopeService:list,projectScopeService:get,proposalService:delete,proposalService:list,proposalService:reject,searchService:list", value: false },
    { name: "list", label: "View Only", api_list: "customerService:list,searchService:createCombinations,projectScopeService:list,projectScopeService:get,proposalService:list,proposalService:get,scopeOfWorkService:list,proposalService:archive,searchService:list", value: false },
]
export const projectManagementPermission = [
    { name: "create", label: "Create", value: false, api_list: "drawingVersionHistoryService:create,drawingVersionHistoryService:list,drawingVersionHistoryService:get,drawingVersionService:create,drawingVersionService:list,drawingVersionService:get,revisionHistoryService:create,changeOrderService:create,revisionHistoryService:list,revisionHistoryService:get,listLogService:create,listLogService:get,locationFacilityService:list,packageService:get,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:create,productLengthService:list,inventoryService:get,searchService:list" },
    { name: "update", label: "Update", value: false, api_list: "drawingVersionHistoryService:update,drawingVersionHistoryService:list,drawingVersionHistoryService:get,drawingVersionService:update,drawingVersionService:list,drawingVersionService:get,revisionHistoryService:update,changeOrderService:update,revisionHistoryService:list,revisionHistoryService:get,listLogService:update,changeOrderService:updateStatus,listLogService:updateStatus,locationFacilityService:list,packageService:get,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:update,inventoryService:updateStatus,inventoryService:get,searchService:list" },
    { name: "delete", label: "Delete", value: false, api_list: "drawingVersionHistoryService:delete,drawingVersionHistoryService:list,drawingVersionHistoryService:get,drawingVersionService:delete,drawingVersionService:list,drawingVersionService:get,changeOrderService:delete,revisionHistoryService:delete,revisionHistoryService:list,revisionHistoryService:get,listLogService:delete,locationFacilityService:list,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:delete,drawingService:delete,searchService:list" },
    { name: "list", label: "View Only", value: false, api_list: "drawingVersionHistoryService:list,drawingVersionHistoryService:get,drawingVersionService:list,drawingVersionService:get,changeOrderService:list,revisionHistoryService:list,revisionHistoryService:get,listLogService:list,locationFacilityService:list,productService:list,packageService:get,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:list,inventoryService:stock,inventoryService:get,userService:list,searchService:list" },
]
export const stockTransferServicePermission = [
    // { name: "create", label: "Create", value: false, api_list: "inventoryTransferService:child,inventoryTransferService:history,inventoryTransferService:upload,productLengthService:get,coatingService:get,productService:get,sizeSpecificationService:get,intuitService:get,gradeService:get,deliveryByService:get,locationFacilityService:get,productUnitService:get,productUnitService:list,productLengthService:list,coatingService:list,productService:list,sizeSpecificationService:list,intuitService:list,gradeService:list,deliveryByService:list,locationFacilityService:list,inventoryTransferService:list,inventoryTransferService:create" },
    // { name: "update", label: "Update", value: false, api_list: "inventoryTransferService:history,inventoryTransferService:upload,productLengthService:get,coatingService:get,productService:get,sizeSpecificationService:get,intuitService:get,gradeService:get,deliveryByService:get,locationFacilityService:get,productUnitService:get,productUnitService:list,productLengthService:list,coatingService:list,productService:list,sizeSpecificationService:list,intuitService:list,gradeService:list,deliveryByService:list,locationFacilityService:list,inventoryTransferService:list,inventoryTransferService:update,inventoryTransferService:get" },
    { name: "create", label: "Create", value: false, api_list: "inventoryTransferService:deleteChild,inventoryTransferService:getPickupNumber,inventoryTransferService:checkPickupNumber,inventoryTransferService:editChild,searchService:createCombinations,inventoryTransferService:book,inventoryTransferService:child,inventoryTransferService:history,inventoryTransferService:upload,productLengthService:get,coatingService:get,productService:get,sizeSpecificationService:get,intuitService:get,gradeService:get,deliveryByService:get,locationFacilityService:get,productUnitService:get,productUnitService:list,productLengthService:list,coatingService:list,productService:list,sizeSpecificationService:list,intuitService:list,gradeService:list,deliveryByService:list,locationFacilityService:list,inventoryTransferService:list,inventoryTransferService:create,inventoryService:stock,productLengthService:list" },
    { name: "update", label: "Update", value: false, api_list: "inventoryTransferService:deleteChild,inventoryTransferService:getPickupNumber,inventoryTransferService:checkPickupNumber,searchService:createCombinations,inventoryTransferService:book,inventoryTransferService:edit,inventoryTransferService:history,inventoryTransferService:upload,productLengthService:get,coatingService:get,productService:get,sizeSpecificationService:get,intuitService:get,gradeService:get,deliveryByService:get,locationFacilityService:get,productUnitService:get,productUnitService:list,productLengthService:list,coatingService:list,productService:list,sizeSpecificationService:list,intuitService:list,gradeService:list,deliveryByService:list,locationFacilityService:list,inventoryTransferService:list,inventoryTransferService:update,inventoryTransferService:get,inventoryService:stock,productLengthService:list" },
    { name: "delete", label: "Delete", value: false, api_list: "searchService:createCombinations,inventoryTransferService:history,inventoryTransferService:list,inventoryTransferService:delete,inventoryTransferService:get" },
    { name: "list", label: "View Only", value: false, api_list: "searchService:createCombinations,inventoryTransferService:history,inventoryTransferService:list,inventoryTransferService:get" },
]
export const shopSupplyPermission = [
    { name: "create", label: "Create", value: false, api_list: "saleService:getTax,shopScheduleService:getInvoice,searchService:createCombinations,deliveryByService:list,shopScheduleService:editInvoice,projectService:list,projectService:get,shopService:create,shopService:list,shopService:get,shopScheduleService:schedule,shopScheduleService:deliver,shopScheduleService:rollback,customerService:list,customerService:get,productService:list,productService:get,projectService:list,projectService:get,productLengthService:list,gradeService:list,gradeService:get,coatingService:list,coatingService:get,sizeSpecificationService:list,sizeSpecificationService:get,locationFacilityService:list,locationFacilityService:get,inventoryService:stock"},
    { name: "update", label: "Update", value: false, api_list: "saleService:getTax,shopScheduleService:getInvoice,searchService:createCombinations,deliveryByService:list,productService:list,shopScheduleService:editInvoice,projectService:list,projectService:get,shopService:update,shopService:list,shopService:get,shopScheduleService:schedule,shopScheduleService:deliver,shopScheduleService:rollback,customerService:list,customerService:get,productService:list,productService:get,projectService:list,projectService:get,productLengthService:list,gradeService:list,gradeService:get,coatingService:list,coatingService:get,sizeSpecificationService:list,sizeSpecificationService:get,locationFacilityService:list,locationFacilityService:get,inventoryService:stock"},
    { name: "delete", label: "Delete", value: false, api_list: "searchService:createCombinations,shopService:delete,shopService:list,shopService:get" },
    { name: "list", label: "View Only", value: false, api_list: "searchService:createCombinations,shopService:list,shopService:get,customerService:list,projectService:list" },
]

export const incomingInvoice = [
    { name: "create", label: "Create", value: false, api_list: "incomingInvoiceService:list,incomingInvoiceService:readBy,incomingInvoiceService:accepted,incomingInvoiceService:approved,incomingInvoiceService:trash,incomingInvoiceService:recover"},
    { name: "update", label: "Update", value: false, api_list: ""},
    { name: "delete", label: "Delete", value: false, api_list: "" },
    { name: "list", label: "View Only", value: false, api_list: "incomingInvoiceService:list,incomingInvoiceService:readBy" },
]

export const dashboardPermission = [
    { name: "create", label: "Create", value: false, api_list: "frontDashboardService:get" },
    // { name: "update", label: "Update", value: false, api_list: "locationFacilityService:list,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:update,inventoryService:updateStatus,inventoryService:get" },
    // { name: "delete", label: "Delete", value: false, api_list: "locationFacilityService:list,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:delete" },
    // { name: "list", label: "View Only", value: false, api_list: "locationFacilityService:list,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:list,inventoryService:stock,inventoryService:get" },
]

export const reportsPermission = [
    { name: "create", label: "Create", value: false, },
    { name: "update", label: "Update", value: false, },
    { name: "delete", label: "Delete", value: false, },
    { name: "list", label: "View Only", value: false, api_list: "reportService:list," },
]

export const FrontOfficeRolePermissions = [
    {
        name: "Dashboard",
        type: "frontDashboardService",
        permissions: dashboardPermission,
        user_type: "front-office",
    },
    {
        name: "Customers",
        type: "customerService",
        permissions: customerServicePermission,
        user_type: "front-office",
    },
    {
        name: "Supplier",
        type: "supplierService",
        permissions: supplierServicePermission,
        user_type: "front-office",
    },
    {
        name: "Projects",
        type: "projectService",
        permissions: projectServicePermission,
        user_type: "front-office",
    },
    {
        name: "Distribution",
        type: "inquiryService",
        permissions: inquiryServicePermission,
        user_type: "front-office",
    },
    {
        name: "Shop Supply",
        type: "shopService",
        permissions: shopSupplyPermission,
        user_type: "front-office",
    },
    {
        name: "Sale Order",
        type: "saleOrderService",
        permissions: saleOrderPermission,
        user_type: "front-office",
    },
    {
        name: "Purchase Order",
        type: "purchaseOrderService",
        permissions: purchaseOrderPermission,
        user_type: "front-office",
    },
    {
        name: "Logistic",
        type: "logisticService",
        permissions: logisticServicePermission,
        user_type: "front-office",
    },
    {
        name: "Inventory",
        type: "inventoryService",
        permissions: inventoryServicePermission,
        user_type: "front-office",
    },
    {
        name: "Edit Inventory",
        type: "inventoryService",
        permissions: editInventoryPermission,
        user_type: "front-office",
    },
    {
        name: "Stock Transfer",
        type: "inventoryTransferService",
        permissions: stockTransferServicePermission,
        user_type: "front-office",
    },
    {
        name: "Proposal",
        type: "proposalService",
        permissions: proposalServicePermission,
        user_type: "front-office",
    },
    {
        name: "Project Management",
        type: "projectService",
        permissions: projectManagementPermission,
        user_type: "front-office",
    },
    {
        name: "Reports",
        type: "reportsService",
        permissions: reportsPermission,
        user_type: "front-office",
    },
    {
        name: "Incoming Invoice",
        type: "incomingInvoiceService",
        permissions: incomingInvoice,
        user_type: "front-office",
    }
];

export const BackOfficeRolePermission = [
    {
        name: "Customers",
        type: "customerService",
        permissions: [
            { name: "create", label: "Create", api_list: "customerService:create,customerService:list,customerService:get", value: false },
            { name: "update", label: "Update", api_list: "customerService:update,customerService:updateStatus,customerService:list,customerService:get", value: false },
            { name: "delete", label: "Delete", api_list: "customerService:delete,customerService:list", value: false },
            { name: "list", label: "View Only", api_list: "customerService:list,customerService:get", value: false },
        ],
        status: false,
    },
    {
        name: "Projects",
        type: "projectService",
        permissions: [
            { name: "create", label: "Create", value: false, api_list: "customerService:list,customerService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:create,projectService:list,projectService:get," },
            { name: "update", label: "Update", value: false, api_list: "customerService:list,customerService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:update,projectService:updateStatus,projectService:updatePricing,projectService:list,projectService:get" },
            { name: "delete", label: "Delete", value: false, api_list: "projectService:delete,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:list" },
            { name: "list", label: "View Only", value: false, api_list: "projectService:list,projectService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list" },
        ],
        status: false,
    },

]