import { IAddPurchaseOrderModuleTypes, IPurchaseOrder, IPurchaseOrderTypeRes } from "./types";

export const ActionTypes = {
  SET_STORE_PURCHASE_ORDER_MODULE_LIST: "SET_STORE_PURCHASE_ORDER_MODULE_LIST",
  SET_UPDATE_STATUS:"SET_UPDATE_STATUS",
  SET_DELETE_ORDER:"SET_DELETE_ORDER",
  SET_PRODUCT_DETAILS:"SET_PRODUCT_DETAILS",
};

export const setPurchaseOrderModuleList = (payload: IPurchaseOrderTypeRes) => ({
  type: ActionTypes.SET_STORE_PURCHASE_ORDER_MODULE_LIST,
  payload,
});
export const setPurchaseUpdateStatus = (payload: IPurchaseOrder) => ({
  type: ActionTypes.SET_UPDATE_STATUS,
  payload,
});
export const setPurchaseDeleteOrder = (payload: IPurchaseOrder) => ({
  type: ActionTypes.SET_DELETE_ORDER,
  payload,
});
export const setProductDetails = (payload?: IAddPurchaseOrderModuleTypes) => ({
  type: ActionTypes.SET_PRODUCT_DETAILS,
  payload,
});