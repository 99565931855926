/* eslint-disable array-callback-return */

import { ActionTypes } from './action';
import { IIncomingInvoice } from './types';


const initialState:IIncomingInvoice = {
  incoming_invoice_list:{
    items: [],
    totalCount: 0,
  },
  distribution_invoice_list:{
    items: [],
    totalCount: 0,
  },
  inventory_invoice_list:{
    items: [],
    totalCount: 0,
  },
  logistic_invoice_list:{
    items: [],
    totalCount: 0,
  },
  transfer_invoice_list:{
    items: [],
    totalCount: 0,
  },
  incoming_invoice_detail:null
};

const incomingInvoiceReducer = (state:IIncomingInvoice = initialState, action) => {
  switch (action?.type) {
    case ActionTypes.SET_INCOMING_INVOICE:{
      return {
        ...state,
        incoming_invoice_list:{
          ...action?.payload,
        },
      };
    }
    case ActionTypes.SET_INCOMING_INVOICE_DESC:{
      return {
        ...state,
        incoming_invoice_detail:{
          ...action?.payload,
        },
      };
    }
    case ActionTypes.SET_UPDATE_INCOMING_INVOICE_DESC:{
      const updatedList=state?.incoming_invoice_list?.items?.map((item)=>{
        if(item?.id===action?.payload?.id){
          return action?.payload
        }
        return item
      })
      return {
        ...state,
        incoming_invoice_list:{
          items:updatedList,
          totalCount:state?.incoming_invoice_list?.totalCount
        }
      };
    }
    case ActionTypes.SET_DISTRIBUTION_INCOMING_INVOICE_LIST:{
      return {
        ...state,
        distribution_invoice_list:{
          ...action?.payload,
        },
      };
    }
    case ActionTypes.SET_DELETE_INCOMING_INVOICE_DESC:{
      const updatedList=state?.incoming_invoice_list?.items?.filter((item)=>{
        if(item?.id!==action?.payload?.id){
          return item
        }
      })
      return {
        ...state,
        incoming_invoice_list:{
          items:updatedList,
          totalCount:state?.incoming_invoice_list?.totalCount
        },
        // incoming_invoice_detail:null
      };
    }
    default:
      return state;
  }
};

export default incomingInvoiceReducer;
