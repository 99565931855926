import {IDistributionList, IIncomingEmail } from "./types";

export const ActionTypes = {
  SET_INCOMING_INVOICE: "SET_INCOMING_INVOICE",
  SET_INCOMING_INVOICE_DESC: "SET_INCOMING_INVOICE_DESC",
  SET_UPDATE_INCOMING_INVOICE_DESC: "SET_UPDATE_INCOMING_INVOICE_DESC",
  SET_DELETE_INCOMING_INVOICE_DESC: "SET_DELETE_INCOMING_INVOICE_DESC",
  SET_DISTRIBUTION_INCOMING_INVOICE_LIST: "SET_DISTRIBUTION_INCOMING_INVOICE_LIST",

};

export const setStoreIncomingInvoiceList = (payload: IIncomingEmail) => ({
  type: ActionTypes.SET_INCOMING_INVOICE,
  payload,
});

export const setStoreIncomingInvoiceDesc = (payload: IIncomingEmail) => ({
  type: ActionTypes.SET_INCOMING_INVOICE_DESC,
  payload,
});

export const setUpdateInvoiceList = (payload: IIncomingEmail) => ({
  type: ActionTypes.SET_UPDATE_INCOMING_INVOICE_DESC,
  payload,
});


export const setDeleteInvoiceList = (payload: IIncomingEmail) => ({
  type: ActionTypes.SET_DELETE_INCOMING_INVOICE_DESC,
  payload,
});
export const setDistInvoiceList = (payload: IDistributionList) => ({
  type: ActionTypes.SET_DISTRIBUTION_INCOMING_INVOICE_LIST,
  payload,
});
